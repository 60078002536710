import { links } from '@/router'
import type { RouteLocationRaw } from 'vue-router'
import { useEditorBreadcrumbs } from './useEditorBreadcrumbs'

export function useEditorLinks() {
  const crumbs = useEditorBreadcrumbs()

  function linkWorkflowEdit(workflowId: string): RouteLocationRaw {
    const query = crumbs.reset()
    return { ...links.workflowEdit(workflowId), query }
  }

  function linkSubworkflowEdit(parentWorkflowId: string, subworkflowId: string): RouteLocationRaw {
    const query = crumbs.append(parentWorkflowId)
    return { ...links.workflowEdit(subworkflowId), query }
  }

  function linkParentWorkflowEdit(workflowId: string): RouteLocationRaw {
    const query = crumbs.popAfter(workflowId)
    return { ...links.workflowEdit(workflowId), query }
  }

  function linkBackToWorkflowEdit(workflowId: string): RouteLocationRaw {
    const query = crumbs.currentQuery()
    return { ...links.workflowEdit(workflowId), query }
  }

  function linkReviewData(workflowId: string, workflowBlockId: string): RouteLocationRaw {
    const query = crumbs.currentQuery()
    return { ...links.reviewData(workflowId, workflowBlockId), query }
  }

  return { linkWorkflowEdit, linkReviewData, linkParentWorkflowEdit, linkSubworkflowEdit, linkBackToWorkflowEdit }
}

import { useApiClient } from '@/api'
import type { RunInputDataItemFragment, RunWorkflowDetailsFragment } from '@/generated/sdk'
import { useValidation } from '@madxnl/dodo-ui'
import { computed, reactive, ref } from 'vue'

export function useRunWorkflowForm() {
  const { client } = useApiClient()

  const data = reactive({
    createNewInputData: false,
    name: '',
    text: '',
    selectedInputDataId: '',
  })

  const { validate, errors } = useValidation({
    name: { value: computed(() => data.name), validators: [nameValidator] },
    text: { value: computed(() => data.text), validators: [textValidator] },
  })

  const workflowId = ref('')
  const runWorkflowDetails = ref<RunWorkflowDetailsFragment>()
  const workflowInputArgs = computed(() => runWorkflowDetails.value?.inputArguments ?? [])
  const organizationId = computed(() => runWorkflowDetails.value?.organization.id ?? '')

  const availableInputDatas = ref<RunInputDataItemFragment[]>()

  const json = computed(() => tryParseJson(data.text))
  const jsonDict = computed(() => (isRecord(json.value) ? json.value : {}))

  const inputNames = computed(() => {
    const fromWorkflow = workflowInputArgs.value?.map((arg) => arg.name) ?? []
    const fromData = Object.keys(jsonDict.value)
    const allArgs = [...fromWorkflow, ...fromData]
    const uniqueArgs = [...new Set(allArgs)]
    return uniqueArgs.sort((a, b) => a.localeCompare(b))
  })

  const requiredArgNames = computed(() => {
    if (!workflowInputArgs.value) return []
    const requiredArgs = workflowInputArgs.value.filter((arg) => arg.required && arg.argumentType !== 'Boolean')
    return requiredArgs.map((arg) => arg.name)
  })

  const missingRequiredValues = computed(() =>
    inputNames.value.filter((name) => requiredArgNames.value.includes(name) && !jsonDict.value[name]),
  )

  async function initForm(args: { workflowId: string; useInputData?: boolean; publicRunWorkflow?: boolean }) {
    clear()
    workflowId.value = args.workflowId
    const promises = []

    if (args.publicRunWorkflow) promises.push(fetchPublicWorkflowArgs())
    else promises.push(fetchWorkflowArgs())

    if (args.useInputData) promises.push(fetchInputDatas())
    await Promise.all(promises)
  }

  async function fetchInputDatas() {
    const input = {
      workflow: { id: workflowId.value },
      organization: { id: organizationId.value },
    }
    const response = await client.runInputData({ input })
    availableInputDatas.value = response.runInputData
  }

  async function fetchPublicWorkflowArgs() {
    const response = await client.publicWorkflowInputArguments({ id: workflowId.value })
    runWorkflowDetails.value = response.publicWorkflow
  }

  async function fetchWorkflowArgs() {
    const response = await client.workflowInputArguments({ id: workflowId.value })
    runWorkflowDetails.value = response.workflow[0]
  }

  function loadInputData(inputData: RunInputDataItemFragment) {
    data.name = inputData.name
    data.text = JSON.stringify(inputData.data, null, 2)
  }

  async function submitRun() {
    const valid = await validate()
    if (!valid) return null
    if (data.createNewInputData) await submitInputData()
    const result = await client.runByWorkflowId({ workflowId: workflowId.value, data: json.value })
    clear()
    return result.runByWorkflowId.id
  }

  async function submitInputData() {
    const organization = { id: organizationId.value }
    const workflow = { id: workflowId.value }
    const input = { name: data.name, data: json.value, organization, workflow }
    await client.createRunInputData({ input })
  }

  function clear() {
    workflowId.value = ''
    data.name = ''
    data.text = ''
    data.createNewInputData = false
    data.selectedInputDataId = ''
  }

  // Utility functions
  function isRecord(obj: unknown): obj is Record<string, unknown> {
    return !!obj && typeof obj === 'object' && !Array.isArray(obj)
  }

  function tryParseJson(value: string) {
    try {
      return JSON.parse(value) as unknown
    } catch (e) {
      return value
    }
  }

  function getArgValue(argName: string) {
    const value = jsonDict.value[argName]
    if (!value) return ''
    if (typeof value === 'string') return value
    return value
  }

  function setArgValue(argName: string, value: unknown) {
    const newDict = Object.assign({}, jsonDict.value, { [argName]: value })
    data.text = JSON.stringify(newDict, null, 2)
  }

  function getArgError(argName: string) {
    if (errors.text && missingRequiredValues.value.includes(argName)) return 'Missing required value'
    return undefined
  }

  function getArgDetails(argName: string) {
    const arg = workflowInputArgs.value?.find((arg) => arg.name === argName)
    return arg
  }

  function textValidator() {
    if (missingRequiredValues.value.length) return 'Missing required values'
    return undefined
  }

  function nameValidator(value: string) {
    if (!data.createNewInputData) return undefined
    if (data.createNewInputData && !value) return 'Name is required'
    if (availableInputDatas.value?.find((input) => input.name === value)) return 'Name already exists'
    return undefined
  }

  return {
    json,
    data,
    errors,
    availableInputDatas,
    inputNames,
    requiredArgNames,
    runWorkflowDetails,
    initForm,
    loadInputData,
    submitRun,
    getArgValue,
    getArgDetails,
    setArgValue,
    getArgError,
    clear,
  }
}

<script setup lang="ts">
import { TwinIcon } from '@/ui/components'
import { Icon } from '@madxnl/dodo-ui'
import { watch } from 'vue'
import { useRouter } from 'vue-router'
import { useEditorBreadcrumbs, useEditorLinks, useWorkflowDetails, useWorkflowEditor } from './composables'
import { links } from '@/router'

const limit = 3

const router = useRouter()
const editorLinks = useEditorLinks()
const { breadcrumbs, load, currentQuery } = useEditorBreadcrumbs()
const { workflow } = useWorkflowDetails()
const { selectedBlock } = useWorkflowEditor()

watch(currentQuery, load, { immediate: true })

function resetSelectedBlock() {
  if (selectedBlock.value == null) return
  selectedBlock.value = undefined
}

async function clickExit() {
  await router.push(links.workflows())
}
</script>

<template>
  <div :class="$style.editorbreadcrumbs">
    <button :class="[$style.button, $style.home]" @click="clickExit"><Icon fill name="Home" /></button>
    <TwinIcon :class="$style.chevron" icon="ChevronRight" />

    <template v-for="(crumb, i) in breadcrumbs.slice(-limit)" :key="crumb.id">
      <TwinIcon
        v-if="i"
        :class="$style.chevron"
        icon="ChevronRight"
        style="display: inline-flex; align-items: center"
      />
      <button
        :class="[$style.button, $style.workflow, $style.clickable]"
        @click="router.push(editorLinks.linkParentWorkflowEdit(crumb.id))"
      >
        {{ crumb.name }}
      </button>
    </template>

    <template v-if="workflow">
      <TwinIcon v-if="breadcrumbs.length > 0" :class="$style.chevron" icon="ChevronRight" />
      <button :class="[$style.button, selectedBlock && $style.clickable, $style.workflow]" @click="resetSelectedBlock">
        {{ workflow.name }}
      </button>
    </template>

    <template v-if="selectedBlock">
      <TwinIcon :class="$style.chevron" icon="ChevronRight" />
      <button :class="[$style.button, $style.block]">{{ selectedBlock.name }}</button>
    </template>
  </div>
</template>

<style module>
.editorbreadcrumbs {
  display: flex;
  align-items: center;
  gap: 8px;
}

.button {
  border: none;
  background-color: transparent;
  color: inherit;
  margin: 0;
  font: inherit;
}

.home {
  font-size: 16px;
  cursor: pointer;
}

.home:hover {
  color: var(--brand-color-1);
}

.workflow {
  font-weight: var(--dodo-weight-bold);
}

.clickable {
  cursor: pointer;
  text-decoration: underline;
}

.clickable:hover {
  color: var(--brand-color-1);
}

.chevron {
  color: var(--grey-3-outlines);
}
</style>

<script setup lang="ts">
import SearchableList from '@/ui/components/SearchableList.vue'
import { useWorkflowEditor } from '@/workflow-edit'
import { useDataSources } from '@/workflow-edit/sidebar-right/block-sidebar'
import { Dropdown, TextInput } from '@madxnl/dodo-ui'
import { computed, ref, watch } from 'vue'

const { getAvailableDataSources } = useDataSources()
const { selectedBlock } = useWorkflowEditor()

const props = defineProps<{
  value: string | null
  disabled: boolean
}>()

const emit = defineEmits<{
  change: []
  select: [reference: string]
}>()

const trigger = defineModel<boolean>('trigger')
const open = ref(false)

watch(trigger, triggerOpen, { immediate: true })

function triggerOpen() {
  if (trigger.value) open.value = true
  trigger.value = false
}

const availableDataSources = computed(() => getAvailableDataSources(selectedBlock.value))

const groupedOptions = computed(() => {
  const groups = availableDataSources.value
  return groups
    .map((group) => ({
      groupName: group.groupName,
      items: group.references.map((ref) => ({
        name: ref.label,
        id: ref.value,
        currentValue: ref.value === props.value,
      })),
    }))
    .filter((group) => group.items.length > 0)
})

const currentLabel = computed(() => {
  const options = groupedOptions.value.flatMap((group) => group.items)
  const option = options.find((option) => option.id === props.value)
  return option?.name ?? ''
})

const refNotFound = computed(() => {
  if (props.value == null) return
  return !availableDataSources.value.flatMap(({ references }) => references).some(({ value }) => props.value === value)
})

function select(reference: string) {
  emit('select', reference)
  open.value = false
}
</script>

<template>
  <Dropdown v-model:open="open">
    <template #trigger="{ toggle }">
      <TextInput
        :key="currentLabel"
        :model-value="currentLabel"
        :readonly="!disabled"
        :disabled="disabled"
        :class="[$style.input, !open && refNotFound && $style.errorState]"
        @click="toggle"
      />
    </template>

    <template #content="{ close }">
      <SearchableList header="Select data source" :grouped-options="groupedOptions" @select="select" @close="close" />
    </template>
  </Dropdown>
</template>

<style module>
.input {
  width: 100%;
  cursor: pointer;
  background-color: var(--dodo-color-primary-light);
  border-color: var(--dodo-color-primary);
}
.errorState {
  border-color: var(--dodo-color-danger);
  background-color: var(--red-light);
}
</style>

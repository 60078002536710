import { links } from '@/router'
import { useRouter } from 'vue-router'

export function useLoginRedirect() {
  const router = useRouter()
  const key = 'postLoginRedirect'

  function redirectToLogin() {
    sessionStorage.setItem(key, router.currentRoute.value.fullPath)
    return router.push(links.login())
  }

  function postLoginRedirect() {
    const path = sessionStorage.getItem(key) ?? '/'
    sessionStorage.removeItem(key)
    return router.replace(path)
  }

  return {
    redirectToLogin,
    postLoginRedirect,
  }
}

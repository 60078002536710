import { useApiClient, usePolling } from '@/api'
import { RunStatus, type BlockConfigOutputFragment, type RunItemFragment } from '@/generated/sdk'
import type { ComputedRef } from 'vue'
import { computed, onBeforeUnmount, reactive, ref, watch } from 'vue'

export type BlockOutputDetails = {
  runs: ComputedRef<RunItemFragment[] | undefined>
  parentWorkflow: ComputedRef<{ id: string; name: string } | null>
  workflowBlock: ComputedRef<{ id: string; name?: string | null } | null>
  showMore: ComputedRef<(() => Promise<void>) | null>
}

export function useBlockOutput() {
  const { client } = useApiClient()
  const pagination = reactive({ size: 10, page: 1 })
  const { startPolling, stopPolling } = usePolling(pollFetch)

  onBeforeUnmount(stopPolling)

  const blockConfigId = ref<string>()
  const blockConfigOutput = ref<BlockConfigOutputFragment>()

  const runs = computed(() => {
    return blockConfigOutput.value?.runs.slice().sort((a, b) => parseInt(b.createdAt) - parseInt(a.createdAt))
  })

  const isRunningOrPending = computed(() => {
    return runs.value?.some((run) => run.status === RunStatus.Running || run.status === RunStatus.Pending) ?? false
  })

  const workflowBlock = computed(() => blockConfigOutput.value?.workflowBlock?.[0] || null)
  const parentWorkflow = computed(
    () =>
      runs.value?.[0]?.parentRun?.blockConfig?.workflow ||
      runs.value?.[0]?.parentRun?.parentRun?.blockConfig?.workflow ||
      null,
  )

  async function fetchOutput(id: string) {
    stopPolling()
    if (!id) return
    blockConfigOutput.value = undefined
    blockConfigId.value = id
    pagination.page = 1
    blockConfigOutput.value = await fetch()
    if (isRunningOrPending.value) await startPolling()
  }

  async function pollFetch() {
    blockConfigOutput.value = await fetch()
  }

  watch(
    isRunningOrPending,
    (runningOrPending) => {
      if (!runningOrPending) stopPolling()
    },
    { immediate: true },
  )

  async function fetch() {
    if (!blockConfigId.value) return
    const response = await client.getBlockConfigOutput({
      blockConfigId: blockConfigId.value,
      runArgs: { ...pagination },
    })
    return response.blockConfig[0]
  }

  async function loadMore() {
    stopPolling()
    pagination.page++
    const response = await fetch()
    if (!response) return
    blockConfigOutput.value?.runs.push(...response.runs)
  }

  const showMore = computed(() => {
    const hasMore = runs.value?.length && runs.value?.length === pagination.size * pagination.page
    return hasMore ? loadMore : null
  })

  return {
    runs,
    showMore,
    parentWorkflow,
    workflowBlock,
    fetchOutput,
  }
}

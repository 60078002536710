<script setup lang="ts">
import { TabularExportType } from '@/generated/sdk'
import { MainPageLayout } from '@/navigation/components'
import { links } from '@/router'
import { useRunResults } from '@/runs/composables'
import { TwinIcon } from '@/ui/components'
import { useEditorLinks, useWorkflowDetails } from '@/workflow-edit/composables'
import { Button, Column, Dropdown, Row, Select } from '@madxnl/dodo-ui'
import { watch } from 'vue'
import { useRouter } from 'vue-router'
import DataActionBar from './DataActionBar.vue'
import DataPageTable from './DataPageTable.vue'
import { useWorkflowData } from './useWorkflowData'

defineProps<{
  workflowId: string
}>()

const router = useRouter()
const { linkReviewData } = useEditorLinks()
const { workflow } = useWorkflowDetails()
const { resetSelectedRuns, downloadResultsLatestRun, downloadResultsAllRuns } = useRunResults()
const { selectedBlockConfig, filterOptions, selectedBlockConfigId, blockOutput, initialBlockConfig } = useWorkflowData()

watch(selectedBlockConfigId, resetSelectedRuns, { immediate: true })

async function handleDownloadResults(type: TabularExportType, isLatest: boolean) {
  const blockConfigId = workflow.value?.blockConfigs[0]?.id
  if (!blockConfigId) return
  if (isLatest) await downloadResultsLatestRun(blockConfigId, type)
  else await downloadResultsAllRuns(workflow.value!.id, type)
}

async function handleOpenParentWorkflow() {
  const parentWorkflow = blockOutput.parentWorkflow.value
  if (!parentWorkflow) return

  const workflowBlock = blockOutput.workflowBlock.value
  const route = workflowBlock
    ? linkReviewData(parentWorkflow.id, workflowBlock.id)
    : links.workflowData(parentWorkflow.id)
  await router.push(route)
}
</script>

<template>
  <MainPageLayout full-screen>
    <template v-if="workflow">
      <Column gap="xl">
        <Column>
          <Row>
            <Row grow>
              <h1>Results of workflow: {{ workflow.name }}</h1>
            </Row>

            <Row>
              <Dropdown class="navbar-dropdown">
                <template #trigger="{ toggle }">
                  <Button @click="toggle">
                    <TwinIcon icon="Download" />
                    Download
                  </Button>
                </template>
                <template #content>
                  <Button
                    v-for="(type, index) in [TabularExportType.Excel, TabularExportType.Csv]"
                    :key="index"
                    variant="clear"
                    :class="$style.dropdownButton"
                    @click="handleDownloadResults(type, false)"
                  >
                    Download all ({{ type === TabularExportType.Excel ? '.xlsx' : '.csv' }})
                  </Button>
                  <Button
                    v-for="(type, index) in [TabularExportType.Excel, TabularExportType.Csv]"
                    :key="index + 2"
                    variant="clear"
                    :class="$style.dropdownButton"
                    @click="handleDownloadResults(type, true)"
                  >
                    Download latest ({{ type === TabularExportType.Excel ? '.xlsx' : '.csv' }})
                  </Button>
                </template>
              </Dropdown>
            </Row>
          </Row>

          <p class="form-description">
            Here you can find the latest workflow results. Every line is an instance of the workflow, called a run.<br />
            Each run has a result, as shown in the first column of the table below. The input used to run the workflow
            is visible in the columns after the result column.
          </p>
        </Column>

        <Column>
          <h3>Workflow output and input data</h3>

          <Row padding="m" justify="between" :class="$style.ribbon">
            <Row>
              <h4>Runs from</h4>
              <Select
                v-if="filterOptions"
                v-model="selectedBlockConfigId"
                :options="filterOptions"
                style="max-width: 480px"
              />

              <Button v-if="selectedBlockConfig !== initialBlockConfig" square @click="handleOpenParentWorkflow">
                <TwinIcon color="primary" icon="LinkExternal" />
              </Button>
            </Row>
          </Row>

          <DataPageTable
            v-if="selectedBlockConfig"
            :block-config="selectedBlockConfig"
            :block-output="blockOutput"
            :input-arguments="workflow.inputArguments"
          />
        </Column>
      </Column>
    </template>

    <template #footer>
      <DataActionBar />
    </template>
  </MainPageLayout>
</template>

<style module>
.ribbon {
  background-color: var(--grey-0-white);
  border-top: 2px solid var(--grey-2-lines);
  border-bottom: 2px solid var(--grey-2-lines);
}

.dropdownButton {
  justify-content: start;
}
</style>

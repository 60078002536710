import { useApiClient } from '@/api'
import { BlockConfigArgumentType, type BlockConfigDetailsFragment } from '@/generated/sdk'
import { useBlockTypes } from './useBlockTypes'

export function useConfigureLoopBlock() {
  const { client } = useApiClient()
  const { fetchBlockTypeByName } = useBlockTypes()

  async function configureBlockForLoop(blockConfig: BlockConfigDetailsFragment) {
    const isLoopBlock = blockConfig.block.includes('loop')
    if (!isLoopBlock) return
    const innerConfig = blockConfig.loop?.blockConfig
    const listArgument = blockConfig?.arguments.find((x) => x.name === 'input')
    if (!listArgument) await createLoopArgument(blockConfig)
    if (!innerConfig) await createLoopConfig(blockConfig)
  }

  async function createLoopArgument(blockConfig: BlockConfigDetailsFragment) {
    const result = await client.createBlockConfigArgument({
      input: {
        name: 'input',
        argumentType: BlockConfigArgumentType.Reference,
        value: '',
        blockConfig: { id: blockConfig.id },
      },
    })
    blockConfig.arguments.push(result.createBlockConfigArgument)
  }

  async function createLoopConfig(blockConfig: BlockConfigDetailsFragment) {
    const result = await client.updateBlockConfig({
      input: {
        id: blockConfig.id,
        inheritContext: true,
        loop: {
          id: blockConfig.loop?.id,
          blockConfig: {
            block: (await fetchBlockTypeByName('prompt')).id,
            arguments: [],
          },
        },
      },
    })
    blockConfig.inheritContext = result.updateBlockConfig.inheritContext
    blockConfig.loop = result.updateBlockConfig.loop
  }

  return { configureBlockForLoop }
}

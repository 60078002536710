<script setup lang="ts">
import type { BlockConfigFragment, WorkflowDetailsFragment } from '@/generated/sdk'
import { LargeModalLayout } from '@/ui/components'
import { Button, Column, Row, Tabs, Textarea } from '@madxnl/dodo-ui'
import { computed, onMounted, ref } from 'vue'
import { BlockSettings, type SettingsField } from '../sidebar-right/block-sidebar'
import PromptFieldEditor from './PromptFieldEditor.vue'

const props = defineProps<{
  workflow: WorkflowDetailsFragment
  config: BlockConfigFragment | null
  field: SettingsField
  disabled: boolean
}>()

const emit = defineEmits<{
  changevalue: [value: string | null]
}>()

const dataString = computed(() => String(props.field.data ?? ''))
const currentValue = ref(dataString.value)
const newValue = ref('')
const modalOpen = ref(false)
const name = computed(() => props.field.namePath[props.field.namePath.length - 1]!)

onMounted(loadValue)

function loadValue() {
  currentValue.value = dataString.value
  newValue.value = currentValue.value
}

async function save() {
  currentValue.value = newValue.value
  emit('changevalue', newValue.value)
}

function openModal() {
  modalOpen.value = true
  newValue.value = currentValue.value
}

function onClickOutside() {
  const changed = newValue.value !== currentValue.value
  const doClose = !changed || window.confirm('You have unsaved changes. Are you sure you want to close the modal?')
  if (doClose) {
    modalOpen.value = false
  }
}
</script>

<template>
  <Column>
    <PromptFieldEditor
      :workflow="workflow"
      :config="config"
      :model-value="currentValue"
      :field="field"
      :readonly="true"
      style="max-height: 200px"
      @click="openModal"
    />
  </Column>

  <LargeModalLayout size-xl :open="modalOpen" :title="`${disabled ? 'View' : 'Edit'} ${name}`" @close="onClickOutside">
    <template #content>
      <Row gap="l" align="start">
        <Column grow>
          <Tabs
            :tabs="[
              { name: 'Editor', slot: 'editor' },
              { name: 'Text', slot: 'text' },
            ]"
          >
            <template #editor>
              <Row gap="l">
                <PromptFieldEditor
                  v-model="newValue"
                  :workflow="workflow"
                  :config="config"
                  :field="field"
                  :disabled="disabled"
                />
              </Row>
            </template>
            <template #text>
              <Textarea ref="textarea" v-model="newValue" :disabled="disabled" :max-rows="20" />
            </template>
          </Tabs>
        </Column>
        <Column style="width: 254px">
          <BlockSettings
            :workflow="workflow"
            :config="config"
            :hide-fields="['prompt', 'model', 'template']"
            context="settings"
          />
        </Column>
      </Row>
    </template>
    <template #footer="{ close }">
      <Row>
        <Button @click="close">Cancel</Button>
        <Button variant="solid" color="primary" @click="save().then(close)">Save</Button>
      </Row>
    </template>
  </LargeModalLayout>
</template>

import { useApiClient } from '@/api'
import { useCurrentUser } from '@/auth/composables'
import { useValidation } from '@madxnl/dodo-ui'
import { reactive, ref, toRef } from 'vue'
import { useAddressForm } from '.'

const billingEmail = ref('')
const billingFirstName = ref('')
const billingLastName = ref('')
const billingPhoneNumber = ref('')
const data = reactive({ billingEmail, billingFirstName, billingLastName, billingPhoneNumber })
const billingSameAddress = ref(true)
const billingAddress = useAddressForm()

export function useBillingInfoForm() {
  const { client } = useApiClient()
  const { currentUser } = useCurrentUser()

  const { errors, validate } = useValidation({
    billingEmail: { value: billingEmail, isEmail: true },
    billingFirstName: { value: billingFirstName, required: false },
    billingLastName: { value: billingLastName, required: false },
    billingPhoneNumber: { value: billingPhoneNumber, required: false },
  })

  const organizationId = toRef(() => {
    return currentUser.value?.organization?.id
  })

  async function load() {
    if (!organizationId.value) throw new Error('User has no organization')
    const res = await client.organization({ id: organizationId.value })
    const organization = res.organization[0]
    if (!organization) throw new Error('Organization not found')
    const { billingEmail, billingFirstName, billingLastName, billingPhoneNumber } = organization
    Object.assign(data, { billingEmail, billingFirstName, billingLastName, billingPhoneNumber })
    billingAddress.loadData(organization.billingAddress ?? null)
    billingSameAddress.value = !organization.billingAddress
  }

  async function submit() {
    const isValid = await validate()
    if (!isValid) return false

    if (!billingSameAddress.value) {
      const billingAddressValid = await billingAddress.validate()
      if (!billingAddressValid) return false
    }

    const dataWithAddress = {
      ...data,
      billingAddress: billingSameAddress.value ? null : { ...billingAddress.data },
      id: organizationId.value,
    }
    await client.updateOrganization({ data: dataWithAddress })
    return true
  }

  return { data, load, submit, errors, billingAddress, billingSameAddress }
}

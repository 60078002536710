<script setup lang="ts">
import { useSimpleMessage } from '@/ui/composables'
import { watch } from 'vue'
import { RouterView, useRouter } from 'vue-router'
import { useWorkflowDetails } from '.'
import { links } from '@/router'

const props = defineProps<{
  workflowId: string
}>()

// This provides the workflow details to the children components
const { workflow, loadWorkflow } = useWorkflowDetails()
const { showMessage } = useSimpleMessage()
const router = useRouter()

watch(() => props.workflowId, fetch, { immediate: true })

async function fetch() {
  if (props.workflowId !== workflow.value?.id) {
    workflow.value = undefined
    await loadWorkflow(props.workflowId)
    // Redirect if not found
    if (!workflow.value) {
      showMessage('The workflow was not found', { type: 'danger' })
      return router.replace(links.workflows())
    }
  }
}
</script>

<template>
  <RouterView />
</template>

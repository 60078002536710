<script setup lang="ts">
import { useResetElasticSearch } from '@/auth/composables'
import { MainPageLayout } from '@/navigation/components'
import { TwinIcon } from '@/ui/components'
import { Button, Card, Dropdown, Row } from '@madxnl/dodo-ui'
import { PasswordChangeForm, ProfileForm } from '.'

const { resetElasticSearch } = useResetElasticSearch()
</script>

<template>
  <MainPageLayout>
    <Row justify="between">
      <h1>User settings</h1>

      <Row>
        <Dropdown v-if="resetElasticSearch" class="navbar-dropdown">
          <template #trigger="{ toggle }">
            <Button square @click="toggle"><TwinIcon icon="Settings" /></Button>
          </template>
          <template #content>
            <Button variant="clear" @click="resetElasticSearch"> Reset Elastic Search </Button>
          </template>
        </Dropdown>
      </Row>
    </Row>

    <Card padding="l">
      <h3>Profile</h3>
      <ProfileForm />
    </Card>

    <Card padding="l">
      <h3>Change password</h3>
      <PasswordChangeForm />
    </Card>
  </MainPageLayout>
</template>

<script setup lang="ts">
import { useSimpleMessage } from '@/ui/composables'
import { Button, FormItem, Row, TextInput } from '@madxnl/dodo-ui'
import { onMounted } from 'vue'
import { AddressFields } from '.'
import { useOrganizationForm } from '../composables'

const { errors, load, data, submit, addressForm } = useOrganizationForm()
const { showMessage } = useSimpleMessage()

onMounted(load)

async function clickSubmit() {
  const success = await submit()
  if (success) showMessage('Changes saved')
}

onMounted(load)
</script>

<template>
  <FormItem label="Organization name" :error="errors.name">
    <TextInput v-model="data.name" />
  </FormItem>

  <FormItem label="Email address" :error="errors.email">
    <TextInput v-model="data.email" type="email" name="email" />
  </FormItem>

  <FormItem label="Phone number" :error="errors.phoneNumber">
    <TextInput v-model="data.phoneNumber" />
  </FormItem>

  <AddressFields v-model:address="addressForm.data" :errors="addressForm.errors" />

  <br />
  <Row justify="end">
    <Button color="primary" variant="solid" @click="clickSubmit">Save changes</Button>
  </Row>
</template>

<script lang="ts" setup>
import { CurrentDeleteDialog, OfflineStateOverlay, SimpleMessage } from '@/ui/components'
import { CrashDialog } from '@madxnl/dodo-ui'
import { onErrorCaptured } from 'vue'
import { RouterView, useRouter } from 'vue-router'
import BrowserSupported from './BrowserSupported.vue'
import ReleasePopup from './ReleasePopup.vue'
import { links } from '@/router'

const router = useRouter()

onErrorCaptured((err) => {
  if (err instanceof Error && err.message.startsWith('Forbidden resource:')) {
    router.replace(links.expired()).catch(() => {})
    return false
  }
  return true
})
</script>

<template>
  <BrowserSupported />
  <CrashDialog />
  <SimpleMessage />
  <OfflineStateOverlay>
    <RouterView />
    <CurrentDeleteDialog />
  </OfflineStateOverlay>
  <ReleasePopup />
</template>

<style>
body {
  background: var(--grey-1-altbg);
}
#app {
  display: flex;
  flex-direction: column;
  height: 100vh;
}
/* Wip responsive font sizes */
@media (min-width: 800px) {
  :root {
    --dodo-font-size: 15px;
    --dodo-font-small: 14px;
  }
}
@media (min-width: 1200px) {
  :root {
    --dodo-font-size: 16px;
    --dodo-font-small: 14px;
  }
}
</style>

import { useApiClient } from '@/api'
import {
  BlockConfigArgumentType,
  type BlockConfigArgumentInputSchema,
  type BlockConfigFragment,
  type WorkflowDetailsFragment,
} from '@/generated/sdk'
import { ref } from 'vue'

const subworkflow = ref<WorkflowDetailsFragment>()

export function useSubworkflowSettings() {
  const { client } = useApiClient()
  const { Constant } = BlockConfigArgumentType

  async function loadSubworkflow(blockConfig: BlockConfigFragment | null) {
    const isLoaded = subworkflow.value?.id === blockConfig?.workflow?.id
    if (isLoaded) return
    subworkflow.value = undefined
    const id = blockConfig?.workflow?.id
    if (!id) return
    const response = await client.workflowDetails({ id })
    subworkflow.value = response.workflow[0]

    // Sync block config arguments with the subworkflow input arguments
    const inputs = subworkflow.value?.inputArguments ?? []
    const unmatchedArgs = [...blockConfig.arguments]
    const missingArgs: BlockConfigArgumentInputSchema[] = []
    for (const input of inputs) {
      const arg = unmatchedArgs.find((a) => a.name === input.name)
      if (arg) {
        unmatchedArgs.splice(unmatchedArgs.indexOf(arg), 1) // Remove from unmatched if found
      } else {
        missingArgs.push({ name: input.name, argumentType: Constant, value: '' }) // Add if missing
      }
    }
    // Delete unmatched arguments and create missing arguments
    await Promise.all(unmatchedArgs.map((a) => client.deleteBlockConfigArgument({ id: a.id })))
    const result = await client.updateBlockConfig({ input: { id: blockConfig.id, arguments: missingArgs } })
    blockConfig.arguments = result.updateBlockConfig.arguments
  }

  return { loadSubworkflow }
}

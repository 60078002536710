<script lang="ts" setup>
import { useCurrentUser } from '@/auth/composables'
import { NavbarItem } from '@/navigation/components'
import { links } from '@/router'
import { TwinIcon } from '@/ui/components'
import { Column, Dropdown } from '@madxnl/dodo-ui'
import { computed } from 'vue'
import { RouterLink, useRouter } from 'vue-router'

const { displayName, currentUser, fullName } = useCurrentUser()
const router = useRouter()

const sectionActive = computed(() => {
  return router.currentRoute.value.matched.some((route) => route.name === 'user')
})
</script>

<template>
  <Dropdown>
    <template #trigger="{ toggle }">
      <NavbarItem :is-active="sectionActive" aria-haspopup="menu" @click="toggle">
        <TwinIcon icon="Account" />
        <span>{{ displayName(12) }}</span>
      </NavbarItem>
    </template>
    <template #content>
      <Column gap="m" :class="$style.dropdown">
        <template v-if="currentUser">
          <Column gap="xs" padding="s">
            <span class="dodo-nowrap" :class="$style.name">{{ fullName }}</span>
            <span class="dodo-nowrap dodo-fade-secondary">{{ currentUser?.email }}</span>
          </Column>
          <hr />
        </template>
        <Column gap="s">
          <RouterLink v-slot="{ isActive }" :to="links.userSetting()">
            <NavbarItem :is-active="isActive">
              <TwinIcon icon="Account" />
              <span>Account</span>
            </NavbarItem>
          </RouterLink>
          <RouterLink v-slot="{ isActive }" :to="links.organization()">
            <NavbarItem :is-active="isActive">
              <TwinIcon icon="Company" />
              <span>Organization</span>
            </NavbarItem>
          </RouterLink>
          <RouterLink v-slot="{ isActive }" :to="links.help()">
            <NavbarItem :is-active="isActive">
              <TwinIcon icon="Question" />
              <span>Help</span>
            </NavbarItem>
          </RouterLink>
        </Column>
        <template v-if="currentUser">
          <hr />
          <RouterLink :to="links.logout()">
            <NavbarItem>
              <TwinIcon icon="LogOut" />
              <span>Log out</span>
            </NavbarItem>
          </RouterLink>
        </template>
      </Column>
    </template>
  </Dropdown>
</template>

<style module>
.dropdown {
  width: 200px;
}
.name {
  font-weight: 600;
}
</style>

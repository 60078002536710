<script setup lang="ts">
import { MainPageLayout } from '@/navigation/components'
import TwinIcon from '@/ui/components/TwinIcon.vue'
import { Button, Column, Row } from '@madxnl/dodo-ui'
import { ref } from 'vue'
import ReleaseNotes from './ReleaseNotes.vue'

const showNotes = ref(false)
</script>

<template>
  <MainPageLayout>
    <h1>Help</h1>

    <Column gap="l">
      <p>
        This help section will be expanded upon in the future. For now you can check out our knowledge base or view
        recent changes.
      </p>

      <Row>
        <Button @click="showNotes = true">View release notes</Button>

        <a href="https://support.twinai.nl/en/docs" target="_blank" rel="noopener noreferrer">
          <Button>
            Knowledge base
            <TwinIcon icon="LinkExternal" />
          </Button>
        </a>
      </Row>
    </Column>
  </MainPageLayout>

  <ReleaseNotes :open="showNotes" @close="showNotes = false" />
</template>

<script lang="ts" setup>
import type { NavEntry } from '@/navigation/components'
import { Navbar } from '@/navigation/components'
import { RunsNavItem } from '@/runs'
import { CreditsNavItem, UserNavItem } from '@/user'
import { WorkflowsNavItem } from '@/workflows'
import { Column } from '@madxnl/dodo-ui'

defineProps<{
  overrideNavEntries?: NavEntry[]
  fullScreen?: boolean
}>()

const defaultEntries: NavEntry[] = [
  { component: WorkflowsNavItem, position: 'end', order: 0 },
  { component: RunsNavItem, position: 'end', order: 1 },
  { isSeparator: true, position: 'end', order: 7 },
  { component: CreditsNavItem, position: 'end', order: 14 },
  { component: UserNavItem, position: 'end', order: 16 },
]
</script>

<template>
  <slot name="navbar">
    <Navbar :nav-entries="overrideNavEntries ?? defaultEntries" />
  </slot>

  <Column :class="[$style.page, fullScreen && $style.fullScreen]" gap="l">
    <slot />
  </Column>

  <slot name="footer" />
</template>

<style>
body {
  margin: 0;
}
</style>

<style module>
.page {
  margin: 0 auto;
  width: 1200px;
  max-width: 100%;
  padding: var(--dodo-spacing-xl) var(--dodo-spacing-l);
  flex-grow: 1;
  z-index: 0;
}
.fullScreen {
  width: 100%;
}
</style>

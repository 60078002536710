<script lang="ts" setup>
import { onMounted, watch } from 'vue'
import { RouterView } from 'vue-router'
import { useAuthSession, useCurrentUser, useLoginRedirect } from '../composables'

/**
 * This component is used to require authentication for all child routes.
 * It will redirect to the login page if the user is not authenticated.
 */

const { currentUser } = useCurrentUser()
const { redirectToLogin } = useLoginRedirect()
const { isAuthenticated, loadAuthSession } = useAuthSession()

onMounted(async () => {
  await loadAuthSession()
  if (!isAuthenticated.value) await redirectToLogin()
})

watch(currentUser, async (user) => {
  if (!user) await redirectToLogin()
})
</script>

<template>
  <RouterView v-if="currentUser" />
</template>

import { useApiClient } from '@/api'
import { getVar } from '@/environment'
import type { OAuthConnectionsQuery, OAuthProvidersQuery } from '@/generated/sdk'
import { useConfirmDelete, useSimpleMessage } from '@/ui/composables'
import type { Ref } from 'vue'
import { ref } from 'vue'

const redirectUri = getVar('VITE_APP_URL') + '/user/organization?page=integrations'

export type Provider = OAuthProvidersQuery['oAuthProviders'][0]
export type OAuthToken = OAuthConnectionsQuery['oAuthToken'][0]

/**
 * Generate a cryptographically secure random string for the PKCE code_verifier.
 * Must be between 43 and 128 characters (RFC 7636).
 */
function generateCodeVerifier(): string {
  const array = new Uint8Array(32) // 32 bytes = 256 bits of entropy
  window.crypto.getRandomValues(array)
  return btoa(String.fromCharCode(...array))
    .replace(/\+/g, '-')
    .replace(/\//g, '_')
    .replace(/=+$/, '') // URL-safe base64 encoding
}

/**
 * Generate a SHA-256 code_challenge from the code_verifier,
 * then base64 URL-safe encode it per the PKCE spec (S256).
 */
async function generateCodeChallenge(codeVerifier: string): Promise<string> {
  const encoder = new TextEncoder()
  const data = encoder.encode(codeVerifier)
  const hashBuffer = await window.crypto.subtle.digest('SHA-256', data)
  const hashBytes = new Uint8Array(hashBuffer)
  // Convert to base64 URL-safe
  return btoa(String.fromCharCode(...hashBytes))
    .replace(/\+/g, '-')
    .replace(/\//g, '_')
    .replace(/=+$/, '')
}

export function useIntegrations() {
  const { client } = useApiClient()
  const { showMessage } = useSimpleMessage()
  const { confirmDelete } = useConfirmDelete()

  const providers: Ref<Provider[] | null> = ref(null)
  const connections: Ref<OAuthToken[] | null> = ref(null)

  async function init() {
    // 1. Get the providers first
    const providersRes = await client.oAuthProviders()
    providers.value = providersRes.oAuthProviders

    // 3. Handle possible redirect (if user just authorized)
    await handleRedirect()

    // 4. Get the connections, since the redirect might have added a new one
    const connectionsRes = await client.oAuthConnections()
    connections.value = connectionsRes.oAuthToken
  }

  /**
   * Handle the redirect back from the OAuth provider.
   */
  async function handleRedirect() {
    const urlParams = new URLSearchParams(location.search)
    const providerKey = urlParams.get('state')

    if (!providerKey) {
      return
    }

    const provider = providers.value?.find((p) => p.provider === providerKey)
    if (!provider) {
      return
    }

    const code = urlParams.get('code')
    if (!code) {
      return
    }

    // Clear the query params from the URL
    window.history.replaceState({}, document.title, window.location.pathname)

    try {
      // Retrieve the code_verifier from sessionStorage using providerKey
      const storedVerifierKey = `pkce_verifier_${providerKey}`
      const codeVerifier = sessionStorage.getItem(storedVerifierKey) || ''

      // Optionally remove it from sessionStorage now that it's used
      sessionStorage.removeItem(storedVerifierKey)

      // Send the code and codeVerifier to the server
      await client.connectIntegration({
        data: {
          code,
          provider: provider.provider,
          redirectUri,
          codeVerifier,
        },
      })

      showMessage('Integration connected')
    } catch (e: any) {
      if (e.message?.startsWith?.('#0503')) {
        // Known error: user already connected this provider
        showMessage(e.message.split(':')[0], { type: 'danger', duration: 10000 })
      } else {
        throw e
      }
    }
  }

  /**
   * Generate the authorization URL for the specified provider.
   */
  async function connectProvider(provider: Provider): Promise<void> {
    // Build authorization URL
    const url = new URL(provider.authorizationUrl)
    url.searchParams.append('client_id', provider.clientId)
    url.searchParams.append('redirect_uri', redirectUri)
    url.searchParams.append('response_type', provider.responseType)

    // Optional provider-specific parameters
    if (provider.accessType) {
      url.searchParams.append('access_type', provider.accessType)
    }
    if (provider.scopes) {
      url.searchParams.append('scope', provider.scopes.join(' '))
    }

    // Required: This identifies which provider the user is authorizing (used as state)
    url.searchParams.append('state', provider.provider)

    // PKCE: Provide the code_challenge and method
    if (provider.codeChallengeMethod) {
      if (provider.codeChallengeMethod !== 'S256') {
        throw new Error('Only S256 code challenge method is supported')
      }

      // 1. Generate a new code_verifier
      const codeVerifier = generateCodeVerifier()

      // 2. Store the code_verifier in sessionStorage (keyed by provider to avoid collisions)
      const verifierKey = `pkce_verifier_${provider.provider}`
      sessionStorage.setItem(verifierKey, codeVerifier)

      // 3. Generate the code_challenge from the code_verifier
      const codeChallenge = await generateCodeChallenge(codeVerifier)

      // 4. Add the code_challenge and method to the URL
      url.searchParams.append('code_challenge', codeChallenge)
      url.searchParams.append('code_challenge_method', 'S256')
    }

    window.location.href = url.toString()
  }

  /**
   * Delete an existing OAuth connection after user confirmation.
   */
  async function deleteConnection(connection: OAuthToken) {
    if (await confirmDelete({ name: connection.name ?? connection.provider, type: 'connection' })) {
      await client.deleteOAuthConnection({ id: connection.id })
      showMessage('Connection removed')
      connections.value = connections.value!.filter((c) => c.id !== connection.id)
    }
  }

  return {
    providers,
    connections,
    connectProvider,
    deleteConnection,
    init,
  }
}

import { useApiClient } from '@/api'
import type { RunInputSchema, TabularExportType } from '@/generated/sdk'
import { useBlockOutput } from '@/workflow-edit/sidebar-right/block-sidebar'
import { ref } from 'vue'

const selectedRunIds = ref<string[]>([])

export function useRunResults() {
  const api = useApiClient()
  const { runs, fetchOutput } = useBlockOutput()

  function toggleRun(runId: string) {
    if (selectedRunIds.value.includes(runId)) {
      const index = selectedRunIds.value.indexOf(runId)
      selectedRunIds.value.splice(index, 1)
      return
    }
    selectedRunIds.value.push(runId)
  }

  function selectAllRuns(runIds: string[]) {
    selectedRunIds.value = runIds
  }

  function resetSelectedRuns() {
    selectedRunIds.value = []
  }

  async function updateRun(input: RunInputSchema) {
    await api.client.updateRun({ input })
  }

  async function deleteSelectedRuns() {
    const promises = selectedRunIds.value.map((runId) => api.client.deleteRun({ runId }))
    await Promise.all(promises)
    resetSelectedRuns()
  }

  async function downloadResultsAllRuns(workflowId: string, type: TabularExportType) {
    const response = await api.client.downloadRunsByWorkflowId({ workflowId, type })
    await api.downloadFile(response.downloadRunsByWorkflowId)
  }

  async function downloadResultsLatestRun(blockConfigId: string, type: TabularExportType) {
    await fetchOutput(blockConfigId)
    const runId = runs.value?.[0]?.id
    if (!runId) return

    const response = await api.client.downloadRunByRunIds({ runIds: [runId], type })
    await api.downloadFile(response.downloadRunByRunIds)
  }

  async function downloadSelectedRuns(type: TabularExportType) {
    const response = await api.client.downloadRunByRunIds({ runIds: selectedRunIds.value, type })
    await api.downloadFile(response.downloadRunByRunIds)
    resetSelectedRuns()
  }

  return {
    selectedRunIds,
    toggleRun,
    selectAllRuns,
    resetSelectedRuns,
    deleteSelectedRuns,
    downloadResultsAllRuns,
    downloadResultsLatestRun,
    downloadSelectedRuns,
    updateRun,
  }
}

<script setup lang="ts">
import { useSimpleMessage } from '@/ui/composables'
import { Button, FormItem, Row, TextInput } from '@madxnl/dodo-ui'
import { onMounted } from 'vue'
import { AddressFields } from '.'
import { useBillingInfoForm } from '../composables'

const { errors, load, data, submit, billingAddress, billingSameAddress } = useBillingInfoForm()
const { showMessage } = useSimpleMessage()

onMounted(load)

async function clickSubmit() {
  const success = await submit()
  if (success) showMessage('Changes saved')
}
</script>

<template>
  <FormItem label="Email" :error="errors.billingEmail">
    <TextInput v-model="data.billingEmail" type="email" />
  </FormItem>

  <FormItem label="First name" :error="errors.billingFirstName">
    <TextInput v-model="data.billingFirstName" />
  </FormItem>

  <FormItem label="Last name" :error="errors.billingLastName">
    <TextInput v-model="data.billingLastName" />
  </FormItem>

  <FormItem label="Phone number" :error="errors.billingPhoneNumber">
    <TextInput v-model="data.billingPhoneNumber" />
  </FormItem>

  <FormItem label="Use organization address as billing address">
    <Row><input v-model="billingSameAddress" type="checkbox" /></Row>
  </FormItem>
  <template v-if="!billingSameAddress">
    <AddressFields v-model:address="billingAddress.data" :errors="billingAddress.errors" />
  </template>

  <br />
  <Row justify="end">
    <Button color="primary" variant="solid" @click="clickSubmit">Save changes</Button>
  </Row>
</template>

<script lang="ts" setup>
import { links } from '@/router'
import { Button } from '@madxnl/dodo-ui'
import { onMounted } from 'vue'
import { RouterLink } from 'vue-router'
import { useAuthSession } from '../composables'
import AuthPageLayout from './AuthPageLayout.vue'

/**
 * This component is used as a route to log the user out.
 */

defineProps<{
  expired?: boolean
}>()

const { logoutAuth } = useAuthSession()

onMounted(logoutAuth)
</script>

<template>
  <AuthPageLayout
    :title="expired ? 'Your session has expired' : 'You are now logged out'"
    subtitle="Please log in again to continue."
    full-width
    centered
  >
    <RouterLink :to="links.login()">
      <Button variant="solid" color="primary">Proceed to the login</Button>
    </RouterLink>
  </AuthPageLayout>
</template>

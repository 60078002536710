<script setup lang="ts">
import type { RunItemFragment } from '@/generated/sdk'
import { LargeModalLayout, TwinIcon } from '@/ui/components'
import { Button, FormItem, Textarea } from '@madxnl/dodo-ui'
import { ref, watch } from 'vue'

const props = defineProps<{
  run: RunItemFragment
}>()

const emit = defineEmits<{
  updateNotes: [run: RunItemFragment, notes: string | null]
}>()

const open = ref(false)
const notes = ref(props.run.notes)

watch(open, () => {
  if (open.value) {
    notes.value = props.run.notes
  }
})

function clickSaveNotes(close: () => void) {
  emit('updateNotes', props.run, notes.value ?? null)
  close()
}
</script>

<template>
  <Button :class="$style.button" size="s" :color="run.notes ? 'primary' : undefined" round @click="open = true">
    <TwinIcon icon="Message" />
  </Button>

  <LargeModalLayout :open="open" title="Notes of this run" @close="open = false">
    <template #content>
      <FormItem label="Notes">
        <Textarea v-model="notes" />
      </FormItem>
    </template>

    <template #footer="{ close }">
      <Button variant="solid" color="primary" @click="clickSaveNotes(close)">Save</Button>
    </template>
  </LargeModalLayout>
</template>

<style module>
.button {
  --button-color: var(--grey-3-outlines);
}
</style>

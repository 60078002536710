<script lang="ts" setup>
import { version } from '@/../package.json'
import { useCurrentUser } from '@/auth/composables'
import { ref, watch } from 'vue'
import ReleaseNotes from './ReleaseNotes.vue'

const { currentUser } = useCurrentUser()

const key = 'release-popup-dismissed'
const wasDismissed = ref(versionIsGreater(localStorage.getItem(key) || '', version))
const open = ref(false)

watch(currentUser, openIfAuthenticated, { immediate: true })

function openIfAuthenticated() {
  if (currentUser.value) {
    open.value = !wasDismissed.value
  }
}

function versionIsGreater(versionA: string, versionB: string) {
  // Return true if versionA is greater than or equal to versionB
  return versionA.split('.').every((v, i) => parseInt(v) >= parseInt(versionB.split('.')[i] ?? ''))
}

function close() {
  localStorage.setItem(key, version)
  wasDismissed.value = true
  open.value = false
}
</script>

<template>
  <ReleaseNotes :open="open" @close="close" />
</template>

import { useApiClient } from '@/api'
import { useCurrentUser } from '@/auth/composables'
import { useValidation } from '@madxnl/dodo-ui'
import { reactive, ref, toRef } from 'vue'
import { useAddressForm } from '.'

const name = ref('')
const email = ref('')
const phoneNumber = ref('')
const data = reactive({ name, email, phoneNumber })
const addressForm = useAddressForm()

export function useOrganizationForm() {
  const { client } = useApiClient()
  const { currentUser } = useCurrentUser()

  const { errors, validate } = useValidation({
    name: { value: name, required: true },
    email: { value: email, isEmail: true, required: true },
    phoneNumber: { value: phoneNumber, required: false },
  })

  const organizationId = toRef(() => {
    return currentUser.value?.organization?.id
  })

  async function load() {
    if (!organizationId.value) throw new Error('User has no organization')
    const res = await client.organization({ id: organizationId.value })
    const organization = res.organization[0]
    if (!organization) throw new Error('Organization not found')
    const { name, email, phoneNumber, address } = organization
    Object.assign(data, { name, email, phoneNumber })
    addressForm.loadData(address ?? null)
  }

  async function submit() {
    const isValid = await validate()
    const addressValid = await addressForm.validate()
    if (!isValid || !addressValid) return false

    const dataWithAddress = {
      name: data.name,
      phoneNumber: data.phoneNumber,
      email: data.email,
      address: { ...addressForm.data },
      id: organizationId.value,
    }
    await client.updateOrganization({ data: dataWithAddress })
    return true
  }

  return { data, addressForm, load, submit, errors }
}

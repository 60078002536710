<script setup lang="ts">
import type { BlockConfigDetailsFragment, WorkflowDetailsFragment } from '@/generated/sdk'
import { BlockConfigArgumentType } from '@/generated/sdk'
import { TwinIcon } from '@/ui/components'
import { Button, Form, FormItem, Row, TextInput } from '@madxnl/dodo-ui'
import { computed, reactive, toRefs, watch } from 'vue'
import BlockSettingsField from './BlockSettingsField.vue'
import { useSubworkflowSettings } from './composables'
import { useFieldGeneration } from './composables/useFieldGeneration'
import { useManageArguments } from './composables/useManageArguments'

const props = defineProps<{
  workflow: WorkflowDetailsFragment
  config: BlockConfigDetailsFragment | null
  context: 'input' | 'workflow-output' | 'settings'
  hideFields?: string[]
}>()

const { config, workflow } = toRefs(props)
const configOrWorkflow = computed(() => {
  if (props.context === 'workflow-output') return workflow.value
  return config.value
})
const { generatedFields } = useFieldGeneration({ config: configOrWorkflow })
const { addExtraArgument, canAddExtraArgument, isNameTaken } = useManageArguments({ workflow, config })
const { loadSubworkflow } = useSubworkflowSettings()

const subworkflowId = computed(() => props.config?.workflow?.id)
watch(subworkflowId, () => loadSubworkflow(props.config), { immediate: true })

const disabled = computed(() => !workflow.value?.draft)

const data = reactive({
  name: '',
})

async function clickAddArgument() {
  const argumentType = props.context === 'input' ? BlockConfigArgumentType.Reference : BlockConfigArgumentType.Constant
  await addExtraArgument(data.name, argumentType)
  data.name = ''
}

const showAddArgument = computed(() => {
  if (disabled.value) return false
  if (!canAddExtraArgument.value) return false
  if (subworkflowId.value) return false // Don't show add argument button for subworkflow settings
  return true
})
</script>

<template>
  <p v-if="!generatedFields.length" class="form-description">
    <template v-if="context === 'workflow-output'">
      Add the expected output data generated by the workflow. After running the workflow, this output will be shown in
      the results of the workflow.
    </template>
    <template v-else>No {{ context }} items yet</template>
  </p>

  <template v-else>
    <template v-for="(field, i) of generatedFields" :key="i">
      <BlockSettingsField
        v-if="!hideFields?.includes(field.namePath.join('.'))"
        :workflow="workflow"
        :config="config"
        :disabled="disabled"
        :field="field"
      />
    </template>
  </template>

  <Form v-if="showAddArgument" @submit="clickAddArgument">
    <hr />
    <h5>
      <template v-if="context === 'workflow-output'">Add expected output data</template>
      <template v-else> Add a new {{ context }} item </template>
    </h5>
    <FormItem>
      <Row>
        <TextInput v-model="data.name" style="flex: 1" type="text" placeholder="Enter a name" />
        <Button :disabled="data.name === '' || !isNameTaken" type="submit" square>
          <TwinIcon icon="Plus" />
        </Button>
      </Row>
    </FormItem>
  </Form>
</template>

<script lang="ts" setup>
import type { BlockConfigDetailsFragment } from '@/generated/sdk'
import { LargeModalLayout, TwinIcon, TypedInput } from '@/ui/components'
import { useOpenRunBlockModal, useSimpleMessage } from '@/ui/composables'
import { Button, FormItem, Row } from '@madxnl/dodo-ui'
import { toRefs } from 'vue'
import { useRunBlockConfigForm } from './useRunBlockConfigForm'

const props = defineProps<{
  blockConfig?: BlockConfigDetailsFragment
}>()

const emits = defineEmits<{
  close: []
  submit: []
}>()

const { blockConfig } = toRefs(props)

const { open, closeRunBlockModal } = useOpenRunBlockModal()
const { showMessage } = useSimpleMessage()
const { generatedFields, submitRun, setFieldData } = useRunBlockConfigForm({ config: blockConfig })

async function handleRunWorkflowBlock() {
  await submitRun()
  showMessage('Running block')
  closeRunBlockModal()
  emits('submit')
}
</script>

<template>
  <LargeModalLayout :open="open" title="Run block" @close="closeRunBlockModal">
    <template #content>
      <p v-if="generatedFields.length === 0">No block input arguments detected. Please add them in the editor.</p>
      <template v-else>
        <template v-for="(field, i) of generatedFields" :key="i">
          <FormItem :label="field.namePath.join('.')">
            <TypedInput
              :type="field.blockTypeArg"
              :model-value="field.data"
              @update:model-value="(value) => setFieldData(field, value)"
            />
          </FormItem>
        </template>
      </template>
    </template>

    <template #footer="{ close }">
      <Row>
        <Button color="primary" variant="solid" @click="handleRunWorkflowBlock">
          <TwinIcon icon="Play" />
          Run now
        </Button>
        <Button style="order: -1" @click="close">Cancel</Button>
      </Row>
    </template>
  </LargeModalLayout>
</template>
